// extracted by mini-css-extract-plugin
export var blocksInRowsWithOneActiveInnerWrapper = "payload-module--blocks-in-rows-with-one-active-inner-wrapper--c69b5";
export var blocksInRowsWithOneActiveSection = "payload-module--blocks-in-rows-with-one-active-section--c6407";
export var blocksRedirectingToResourcesTitlesContainer = "payload-module--blocks-redirecting-to-resources-titles-container--64d92";
export var contactButtonImageSection = "payload-module--contact-button-image-section--2f0c9";
export var contactButtonImageSectionContainer = "payload-module--contact-button-image-section-container--6800b";
export var contactButtonImageSectionImageContainer = "payload-module--contact-button-image-section-image-container--9ab7d";
export var contactButtonImageSectionInnerWrapper = "payload-module--contact-button-image-section-inner-wrapper--5f1f6";
export var containerWithTwoColumns = "payload-module--container-with-two-columns--9a371";
export var content = "payload-module--content--2526f";
export var customSubtitle = "payload-module--custom-subtitle--65bb0";
export var flipCardsContainer = "payload-module--flip-cards-container--ce88f";
export var flipCardsInnerWrapper = "payload-module--flip-cards-inner-wrapper--57bb0";
export var flipCardsTitlesContainer = "payload-module--flip-cards-titles-container--03414";
export var greenBlocksTitlesContainer = "payload-module--green-blocks-titles-container--3d2a2";
export var opacitySliderInnerWrapper = "payload-module--opacity-slider-inner-wrapper--06d48";
export var opacitySliderTitlesContainer = "payload-module--opacity-slider-titles-container--2bb63";
export var singleBlockContainer = "payload-module--single-block-container--bf3a4";
export var techStackContainerWithTwoColumns = "payload-module--tech-stack-container-with-two-columns--9d13a";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--704a3";
export var techStackSubtitleStyles = "payload-module--tech-stack-subtitle-styles--84199";
export var techStackTitlesContainer = "payload-module--tech-stack-titles-container--689eb";
export var titleBorderWidth = "payload-module--title-border-width--e5248";
export var titlesContainer = "payload-module--titles-container--55a08";
export var underHeaderImageContainer = "payload-module--under-header-image-container--c01c7";
export var underHeaderInnerWrapper = "payload-module--under-header-inner-wrapper--0bd39";