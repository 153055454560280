import React from 'react';

import Layout from '@src/layouts';

import {
  MobileHeaderLayoutB,
  DesktopHeaderLayoutB,
  SectionUnderHeaderWithUnderline,
  BlocksInRowsWithOneActive,
  ContactButtonImageSection,
  FlipCardsSection,
  BlocksChangingBackgroundColorOnHover,
  OpacityScrolledSlider,
  TechStackWithCategories,
  BlocksRedirectingToResources,
  ColoredBlocksWithRedirects,
} from '@commons/index';

import SEO from '@commons/SEO';

import {
  SEOProps,
  headerProps,
  sectionUnderHeaderProps,
  blocksInRowsWithOneActiveProps,
  contactButtonImageSectionProps,
  flipCardsSectionProps,
  blocksChangingBackgroundColorOnHoverProps,
  opacityScrolledSliderProps,
  techStackWithCategoriesProps,
  blocksRedirectingToResourcesProps,
  coloredBlocksWithRedirectsProps,
} from '@pages-impl/devops/payload';

export default function Devops(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Accelerate your operations with DevOps"
      {...props}
    >
      <SEO {...SEOProps} />
      <MobileHeaderLayoutB {...headerProps} />
      <DesktopHeaderLayoutB {...headerProps} />
      <SectionUnderHeaderWithUnderline {...sectionUnderHeaderProps} />
      <BlocksInRowsWithOneActive {...blocksInRowsWithOneActiveProps} />
      <ContactButtonImageSection {...contactButtonImageSectionProps} />
      <FlipCardsSection {...flipCardsSectionProps} />
      <BlocksChangingBackgroundColorOnHover {...blocksChangingBackgroundColorOnHoverProps} />
      <OpacityScrolledSlider {...opacityScrolledSliderProps} />
      <TechStackWithCategories {...techStackWithCategoriesProps} />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
      <ColoredBlocksWithRedirects {...coloredBlocksWithRedirectsProps} />
    </Layout>
  );
}
