import React from 'react';
import { paths } from '@src/constants/pathsEnum';

import featuredImage from './images/featured-image.jpg';
import sectionImage from '@commons/contact-button-image-section/images/image2.svg';

import {
  ansible,
  bamboo,
  elkStack,
  fluentd,
  git,
  gitLab,
  grafana,
  groovy,
  helm,
  jenkins,
  juju,
  prometheus,
  puppet,
  saltStack,
  spinnaker,
  terraform,
  travisCI,
  vagrant,
  vault,
  zuul,
  argo,
  gitHubActions,
  circleCI,
  chef,
} from '@images/logos-techstack';

import * as styles from './payload.module.scss';

export const SEOProps = {
  title: 'DevOps Services to Accelerate Deployment | CodiLime Company',
  description:
    'DevOps as a service can help you transform your business. See how we use our 12 years of experience and knowledge to guide our clients toward agility.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const headerProps = {
  title: (
    <>
      DevOps <br />
      services
    </>
  ),
  isBorder: false,
};

export const sectionUnderHeaderProps = {
  contactButtonLabel: 'Turn on accelerating',
  textPartOne: (
    <>
      Keeping up with the pace of technology development is a challenging task, but every company has to do so to stay
      competitive on the market. Comprehensive DevOps services can help you evolve toward the
    </>
  ),
  textPartTwo: (
    <>
      {' '}
      modern and agile organization you want to become. The CodiLime team will support you in your journey to
      automation.
    </>
  ),
};

export const blocksInRowsWithOneActiveProps = {
  title: 'How can our DevOps experts help you?',
  subtitle: (
    <>
      DevOps practices are traditionally understood as a methodology, but to answer our clients’ needs, we provide{' '}
      <b>DevOps as a service.</b> We help bring development and operations teams together throughout the whole product
      development lifecycle. We use the best-suited technology and tools to streamline and automate your IT operations
      in any project.
    </>
  ),
  id: 'our-services',
  blocks: [
    {
      title: 'Continuous Integration and Continuous Delivery',
      description: (
        <>
          Continuous Integration means automatically integrating code changes from multiple developers into a shared
          repository and running automated tests. Continuous Delivery involves automating the process of deploying code
          to test and production environments. These DevOps services allow teams to consistently release new features
          and updates, even <b>without manual intervention</b> (Continuous Deployment).
        </>
      ),
    },
    {
      title: 'Infrastructure configuration management',
      description: (
        <>
          We manage the configuration of IT infrastructure and applications using suitable tools and processes. This
          helps teams ensure that <b>infrastructure is consistent</b> across different environments and it’s easy to
          deploy changes to the configuration. We also manage the capacity of IT infrastructure to meet the needs of
          applications. That way, you will be sure your infrastructure can scale to meet changing demand.
        </>
      ),
    },
    {
      title: 'Security Management (DevSecOps)',
      description: (
        <>
          We provide an overview of system security by integrating static dependencies vulnerability scanners with
          existing CI pipelines. We conduct security audits of cloud infrastructure, e.g. review of Identity and Access
          configuration or review of cloud network access policies. We help with{' '}
          <b>increasing the security of cloud computing.</b>
        </>
      ),
    },
    {
      title: 'Cloud Expertise',
      description: (
        <>
          We use cloud-based platforms and tools for continuous integration, continuous delivery, and infrastructure
          automation and leverage the scalability and flexibility of the cloud to support agile software development
          processes. Our experts will help you <b>improve the speed and reliability</b> of your software delivery
          processes and take advantage of the benefits of cloud computing.
        </>
      ),
    },
    {
      title: 'Infrastructure as Code',
      description: (
        <>
          We can create a new IaC-based environment for your applications, help you with improving your existing IaC to
          get it to another level, or even migrate your current manual environment to an IaC-based installation.
          Infrastructure automation enables <b>faster execution</b> and eliminates human error.
        </>
      ),
    },
  ],
  classNames: {
    section: styles.blocksInRowsWithOneActiveSection,
    innerWrapper: styles.blocksInRowsWithOneActiveInnerWrapper,
    subtitleStyles: styles.customSubtitle,
    singleBlockContainer: styles.singleBlockContainer,
    titlesContainer: styles.titlesContainer,
  },
};

export const contactButtonImageSectionProps = {
  sectionProps: {
    classNames: {
      innerWrapper: styles.contactButtonImageSectionInnerWrapper,
      section: styles.contactButtonImageSection,
    },
  },
  title: <>Transform your business with DevOps</>,
  buttonTitle: 'Contact us',
  image: sectionImage,
  imageAlt: 'DevOps can help you transform your business',
  isH2: true,
  classNames: {
    customContent: styles.content,
    imageContainer: styles.contactButtonImageSectionImageContainer,
    customContainer: styles.contactButtonImageSectionContainer,
  },
};

export const flipCardsSectionProps = {
  sectionProps: {
    title: 'Our expertise in practice',
    id: 'use-cases',
    subtitle: (
      <>
        At CodiLime, we have worked with agile practices for over 12 years. Through various projects, we have gained the
        experience that allows us to provide efficient knowledge transfer and support for our clients. See how our
        DevOps services have helped our clients.
      </>
    ),
    classNames: {
      innerWrapper: styles.flipCardsInnerWrapper,
      titlesContainer: styles.flipCardsTitlesContainer,
    },
  },
  customCardsContainerStyles: styles.flipCardsContainer,
  isFlippable: true,
  flipCardsData: [
    {
      title: 'CI/CD and testing for a monitoring and alerting service',
      description: (
        <>
          We built a continuous integration system that is easy to use and simple to develop for multiple engineers
          around the world. We used <b>cloud-based architecture</b> to create a complete build system and a test
          automation framework. Our expertise in automation allowed us to reduce development cycle time by 50% and
          provide the client with a reliable, scalable solution.
        </>
      ),
      color: '#14212A',
      paddingTop: '100px',
    },
    {
      title: 'Automating and securing data streaming',
      description: (
        <>
          We built a solution that enabled the transmission of large amounts of AI-generated data in a secure manner.
          The data contained sensitive information and could not be anonymized, so we developed a{' '}
          <b>streaming method with authorization and authentication.</b> We also automated the data transfer process to
          move, catalog, and archive data efficiently. We used Kafka, Druid, Kubernetes, and AWS cloud solutions.
        </>
      ),
      color: '#223745',
      paddingTop: '100px',
    },
    {
      title: 'Designing applications to integrate with Kubernetes and Cloud environments',
      description: (
        <>
          We ensured that applications were prepared to properly expose their logs and metrics and securely communicate
          with both other applications and the platform itself. In addition, platform autoscaling was handled. Running
          applications in the cloud or on Kubernetes requires developers to <b>properly integrate their applications</b>{' '}
          to make use of the underlying platform in order to run smoothly.
        </>
      ),
      color: '#1C2C38',
      paddingTop: '100px',
    },
    {
      title: 'Dynamic multi-cloud environment preparation and DevSecOps monitoring',
      description: (
        <>
          We prepared environments for the customer based on one configuration file. We put Kubernetes platforms on
          multiple clouds and combined them into a single virtual network. Our solution helped in{' '}
          <b>capturing network traffic on the client&apos;s infrastructure</b> and verifying that it complies with
          security policies established by the security department.
        </>
      ),
      color: '#223745',
      paddingTop: '100px',
    },
  ],
};

export const blocksChangingBackgroundColorOnHoverProps = {
  title: 'Our approach to DevOps consulting services',
  subtitle: (
    <>
      At CodiLime, our goal is to deliver exceptional DevOps solutions to our partners. We have a wide range of
      engineering talent and extensive know-how, as well as best practices in management and communication. We also have
      strong principles that guide us in cooperation with our partners.
    </>
  ),
  id: 'approach',
};

export const opacityScrolledSliderProps = {
  title: 'How DevOps practices can benefit your business',
  subtitle: (
    <>
      Implementing DevOps with CodiLime helps you gain greater business agility and quickly respond to shifting market
      needs. Our team of automation specialists and talented developers will take the load off your shoulders and
      accelerate your time to market.
    </>
  ),
  textBlocks: [
    <>
      <span>Increased velocity</span> of operations
    </>,
    <>
      <span>Rapid delivery</span> of updates
    </>,
    <>
      <span>Ensured quality</span> of changes
    </>,

    <>
      <span>Scalable infrastructure</span> and development
    </>,

    <>
      Effective <span>collaboration</span>
    </>,

    <>
      Secure and <span>reliable solutions</span>
    </>,

    <>
      <span>Cost efficiency</span>
    </>,
  ],
  classNames: {
    titlesContainer: styles.opacitySliderTitlesContainer,
    innerWrapper: styles.opacitySliderInnerWrapper,
  },
};

export const techStackWithCategoriesProps = {
  sectionProps: {
    title: 'Tech stack',
    subtitle: (
      <>
        DevOps is a methodology that will help you utilize various software tools to reach your business goals. We
        provide a wide range of technologies to fit your business needs. We provide support in deploying DevOps
        solutions and ensuring automation in your IT operations.
      </>
    ),
    classNames: {
      innerWrapper: styles.techStackInnerWrapper,
      titlesContainer: styles.techStackTitlesContainer,
      subtitleStyles: styles.techStackSubtitleStyles,
    },
  },
  classNames: { containerWithTwoColumns: styles.techStackContainerWithTwoColumns },
  blocksForFirstColumn: [
    {
      categoryTitle: 'Automation',
      technologiesData: [ansible, puppet, saltStack, chef],
    },
    {
      categoryTitle: 'CI/CD',
      technologiesData: [circleCI, travisCI, bamboo, jenkins, zuul, spinnaker, gitLab, argo, gitHubActions],
    },
    {
      categoryTitle: 'IaC',
      technologiesData: [vault, terraform, vagrant, helm, juju, groovy, git],
    },
    {
      categoryTitle: 'Monitoring',
      technologiesData: [fluentd, prometheus, grafana, elkStack],
    },
  ],
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'Our publications about DevOps',
    subtitle: (
      <>
        The DevOps approach is a unique mix of culture and technology practices which results in{' '}
        <b>better time to market and smooth communication.</b> Check out our resources to learn about this methodology
        and how DevOps implementation can benefit your software development practice.
      </>
    ),
    classNames: {
      titlesContainer: styles.blocksRedirectingToResourcesTitlesContainer,
    },
  },
  blocks: [
    {
      text: 'CI/CD tools in DevOps—which should you choose?',
      link: '/blog/ci-cd-tools-in-devops/',
    },
    {
      text: 'SDLC methodologies—which one to choose for your project?',
      link: '/blog/sdlc-methodologies/',
    },
    {
      text: 'SRE vs. DevOps—what’s the difference?',
      link: '/blog/sre-vs-devops-what-is-the-difference/',
    },
    {
      text: 'Top DevOps trends to watch',
      link: '/blog/devops-trends/',
    },
  ],
};

export const coloredBlocksWithRedirectsProps = {
  title: 'See our other services',
  subtitle:
    'Besides DevOps and automation, we provide a wide range of other services. Our know-how and experience allow us to participate in various projects - from data engineering to software development.',
  blocks: [
    {
      text: (
        <>
          <span>R&D services</span>
        </>
      ),
      link: paths.SERVICES.R_D,
    },
    {
      text: (
        <>
          <span>Low-level programming</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.LOW_LEVEL,
    },
    {
      text: (
        <>
          <span>Custom software</span>
          <span>development</span>
        </>
      ),
      link: paths.SERVICES.CUSTOM_DEVELOPMENT,
    },
    {
      text: (
        <>
          <span>MVP development</span>
        </>
      ),
      link: paths.SERVICES.MVP,
    },
  ],
};
